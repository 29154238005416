<template>
	<div>
		<div class="out" ref="out">
			<div ref="canvas_out" class="autograph-box">
				<!-- <img src="../assets/img/canvas-j7.png" class="left-top canvas-bg-j" alt="">
				<img src="../assets/img/canvas-j8.png" class="right-top canvas-bg-j" alt="">
				<img src="../assets/img/canvas-j5.png" class="left-bottom canvas-bg-j" alt="">
				<img src="../assets/img/canvas-j6.png" class="right-bottom canvas-bg-j" alt=""> -->
				<div ref="canvas" class="maxWH"></div>
				<img src="../assets/img/text-icon.png" class="qm-text" alt="">
				<img src="../assets/img/yinzhang-bg-logo.png" v-if="!dsDataShow.pic" class="qm-yinzhang-logo" alt="">
				<img :src="dsDataShow.pic" v-if="dsDataShow.pic" class="qm-yinzhang-logo" alt="">
				<div class="text-status">
					<span v-if="stampStatus == 2">{{ failReason }}</span>
				</div>
				<div class="button_fixed" @click="clearBtn"><img src="../assets/img/clear-btn.png"
						alt="">{{ $t('main.eliminate') }}</div>
				<div class="button_fixed1" :style="$i18n.locale == 'en' ? 'left:1.3rem' : ''" @click="restoreBtn"><img
						src="../assets/img/restore.png" alt="">{{ $t('main.revoke') }}</div>
				<div class="submit-btn1" @click="showPopPusBtn"><img src="../assets/img/yinzhang.png"
						alt="">{{ $t('main.updateSignet') }}</div>
			</div>
			<div class="content">
				<div class="qm-tj-box">
					<div class="set-pass-box" @click="showPop()">
						<img src="../assets/img/pass-icon.png" alt="">
						{{ changePass ? $t('main.modify') : $t('main.set') }}{{ $t('main.signpwd') }}
					</div>
					<img class="question" v-show="help.modify_signature_password"
						@click="showHelpContent('modify_signature_password')" src="../assets/img/question.png" alt=""
						srcset="" />
					<div v-show="is_shz" class="err">
						{{ $t('main.ur') }}
					</div>
					<div v-show="no_pass" class="err">
						{{ $t('main.rf') }}，{{ reason }}
					</div>
					<img class="question" v-show="help.signature_submission"
						@click="showHelpContent('signature_submission')" src="../assets/img/question.png" alt=""
						srcset="" />
					<div class="submit-btn" @click="submitAutoGraph">
						<img src="../assets/img/submit-icon.png" alt="">
						{{ $t('main.submita') }}
					</div>
				</div>
				<div class="agree-tip-box">
					<div class="agree-box-out" :style="is_xy_show ? '' : 'visibility: hidden;'">
						<div class="agree-box">
							<input @input="checkedFlag" type="checkbox" value="" id="agree-select" :checked="isKnow" />
							<label id="" for="agree-select">{{ $t('main.agreeC') }}</label>
						</div>
						<div class="agree-text" @click.stop="showContent">
							《{{ xy.title }}》
						</div>
					</div>
					<div class="agree-box-tips">
						{{ $t('main.noteSign') }}
					</div>
				</div>
			</div>

			<div class="marsk-pop">
				<div class="marsk-pop-in">
					<img src="../assets/img/xz-icon.png" alt="">
					<div class="">
						请横屏下操作
					</div>
					<p>提示：旋转前，请先开启手机旋转功能并确保微信是否已开启横屏模式</p>
				</div>

			</div>

			<div class="pop-pus" v-if="showPopPus">
				<div class="bg-img">
					<div class="imgupdate">
						<div class="sc-btn">
							<div class="sc-btn-item" @click="upLoadImg">
								<div v-if="!ds_data.pic">+</div>
								<img v-else :src="showPic.url" alt="" />
								<div v-show="showPic.status == 'loading'" class="loading_i">
									<van-loading type="spinner" color="#fff" size="0.24rem"
										vertical>{{ $t('main.uploading') }}</van-loading>
								</div>
							</div>
							<div class="title">{{ $t('main.npicwhite') }}<br>{{ $t('main.picsize30') }}</div>
						</div>
						<div class="sc-tucheng">
							<img class="dibu" v-if="dsDataIsShow.pic" :src="dsDataIsShow.pic" alt="">
							<div class="tanchuang">{{ $t('main.effpre') }}</div>
						</div>
					</div>
					<div class="sure-logo">
						<div class="sure-img cencan" @click="concatShow(1)">{{ $t('main.cancel') }}</div>
						<div class="sure-img tijiao" @click="concatShow(2)">{{ $t('main.submit') }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="password-box pop" v-if="false">
			<div class="pop-in">
				<div class="title">
					<img src="../assets/img/title-left-icon.png" alt="">
					<span>{{ $t('main.pesignpwd') }}</span>
					<img src="../assets/img/title-right-icon.png" alt="">
				</div>
				<div class="con">
					<div class="con-tips">
						{{ $t('main.pcscc') }}
					</div>
					<div class="pass-input">
						<div class="">
							<label for="">{{ $t('main.password') }}</label><input :placeholder="$t('main.piypwdlest6')"
								type="password">
						</div>
					</div>
					<div class="err-tips">
						<img src="../assets/img/err-tips-icon.png" alt="">
						{{ $t('main.noMatch') }}
					</div>
					<div class="agree-box-out" :style="is_xy_show ? '' : 'visibility: hidden;'">
						<div class="agree-box">
							<input @input="checkedFlag" type="checkbox" value="" id="agree-select" :checked="isKnow" />
							<label id="" for="agree-select">{{ $t('main.agreeC') }}</label>
						</div>
						<div class="agree-text" @click.stop="showContent">
							《{{ xy.title }}》
						</div>
					</div>
				</div>
				<div class="confirm-btn">
					{{ $t('main.determine') }}
				</div>
			</div>

		</div>
		<div class="change-password-box pop" v-show="password_pop_flag">
			<div class="pop-in">
				<img src="../assets/img/close-icon.png" class="close-pop-btn" @click="colsePop()" alt="">
				<div class="title">
					<img src="../assets/img/title-left-icon.png" alt="">
					<span>{{ changePass ? $t('main.modify') : $t('main.set') }}{{ $t('main.signpwd') }} </span>
					<img src="../assets/img/title-right-icon.png" alt="">
				</div>

				<div class="con">
					<div class="con-tips">
						{{ $t('sign.tips') }}
					</div>

					<div class="pass-input">
						<div class="" v-if="changePass">
							<label for="">{{ $t('main.oldpass') }}</label><input :placeholder="$t('main.piypw')"
								v-model="old_password" type="password">
						</div>
						<div class="">
							<label for="">{{ $t('main.password') }}</label><input :placeholder="$t('main.piypwdlest6')"
								v-model="password" type="password">
						</div>
						<div class="">
							<label for="">{{ $t('main.confirmpwd') }}</label><input :placeholder="$t('main.piypwdlast6')"
								v-model="confirm_password" type="password">
						</div>
					</div>
					<div class="err-tips" v-show="err_tips">
						<img src="../assets/img/err-tips-icon.png" alt="">
						{{ err_tips_text }}
					</div>
				</div>
				<div class="confirm-btn" @click="passSubmit()">
					{{ $t('main.determine') }}
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import { Dialog } from 'vant';
export default {
	data() {
		return {
			goRegFlag: true,
			old_password: '',
			password: '',
			confirm_password: '',
			err_tips: false,
			password_pop_flag: false,
			changePass: false,
			canvas: null,
			ele: null,
			autoGraphInfo: null,
			qm_flag: false,
			err_tips_text: '',
			is_shz: false,
			no_pass: false,
			reason: '',
			isKnow: true,
			xy: {
				title: '',
				content: ''
			},
			is_xy_show: false,
			canvas_list: [],
			help: {
				modify_signature_password: null,
				signature_submission: null
			},
			ds_data: {
				pic: ""
			},
			dsDataShow: {
				pic: ""
			},
			dsDataIsShow: {
				pic: ""
			},
			showPic: {
				url: "",
				status: ""
			},
			outline: 0,
			showPopPus: false,
			cahngePop: 0,
			stampStatus: 0,
			failReason: '',
			stamp_id: 0,
			originSignData: ''
		}
	},

	async mounted() {
		if (!localStorage.getItem('helpinfo') || localStorage.getItem('helpinfo') == '{}') {
			this.help = {
				modify_signature_password: null,
				signature_submission: null
			}
		} else {
			if (this.$i18n.locale == 'en') {
				let data = JSON.parse(localStorage.getItem('helpinfo'))
				Object.keys(data).forEach((item) => {
					if (item.indexOf('_intl') != -1) {
						let label = item.split('_intl')[0]
						if (data[item]) {
							data[label] = data[item]
						}
					}
				})
				this.help = data
			} else {
				this.help = JSON.parse(localStorage.getItem('helpinfo'))
			}
		}
		let newdata = ''
			await this.axios.post('common/encrypt',{type:4 + (this.$i18n.locale == 'en' ? 6 : 0)}).then((res)=>{
				newdata = res.data.data.data_encrypted
			})
		this.axios.post('/agreement/detail', { data:newdata }).then((res) => {

			if (!res.data.data.title || !res.data.data.content) {
				this.isKnow = true
				this.is_xy_show = false
				return
			}
			this.is_xy_show = true
			this.xy.title = res.data.data.title
			this.xy.content = res.data.data.content
		})
		this.height = document.body.clientWidth

		await this.axios.get('/artist/stamp?type=1')
			.then((res) => {
				if (res.data.data.stamp) {
					this.cahngePop = 1
					this.stampStatus = res.data.data.status
					this.failReason = res.data.data.fail_reason
					this.dsDataIsShow.pic = res.data.data.stamp
					this.dsDataShow.pic = res.data.data.stamp
					this.stamp_id = res.data.data.id
				}
			})
		await this.getAutoGraph()
		// this.screen()
		this.createCanvas();
		window.addEventListener('resize', () => {
			// this.canvas.width = this.ele.clientWidth;
			// this.canvas.height = this.ele.clientHeight;
			this.canvas.remove()
			this.createCanvas(0);
		})

		let url = location.protocol + '//' + location.host
		let param = this.$route.fullPath
		this.axios.post('/auth/wechatConfig', {
			url: url + this.$store.state.jssdkUrl,
			jsApiList: ['chooseImage', 'getLocalImgData']
		}).then((res) => {

			let json = JSON.parse(res.data.data.data)

			wx.config({
				debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
				appId: json.appId, // 必填，公众号的唯一标识
				timestamp: json.timestamp, // 必填，生成签名的时间戳
				nonceStr: json.nonceStr, // 必填，生成签名的随机串
				signature: json.signature, // 必填，签名
				jsApiList: json.jsApiList // 必填，需要使用的 JS 接口列表
			})

		})

	},
	methods: {
		// screen(){
		// 	
		// 	var fontSizeBody;
		// 	! function(e) {
		// 	    var t = e.document,
		// 	        n = t.documentElement,
		// 	        resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
		// 	        recalc = function() {
		// 	            var e = n.getBoundingClientRect().height || 375;
		// 	            ft = (e > 750 ? e = 750 : e) / 3.75, n.style.fontSize = ft + "px",
		// 				
		// 	            fontSizeBody=ft;


		// 	        }
		// 	    if (!t.addEventListener) return;
		// 	    e.addEventListener(resizeEvt, recalc, false);
		// 	    t.addEventListener('DOMContentLoaded', recalc, false);

		// 	}(window)
		// },
		async getAutoGraph() {
			await this.axios.get('/artist/sign').then((res) => {

				if (res.data.code == 0) {

					this.autoGraphInfo = res.data.data
					if (res.data.data.id != 0) {
						if (res.data.data.status == 0) {
							this.is_shz = true
						} else if (res.data.data.status == 2) {
							this.no_pass = true
							this.reason = res.data.data.fail_reason
						}
					}
					if (res.data.data.has_password != 1) {
						this.changePass = false
					} else {
						this.changePass = true
					}
				}
			})
		},
		addPasswordSubmit() {
			this.axios.post('/artist/sign_password', {
				password: this.password,
				password_confirm: this.confirm_password
			}).then((res) => {

				if (res.data.code == 0) {

					this.password_pop_flag = false
					this.password = ''
					this.confirm_password = ''
					this.changePass = true
					weui.alert(this.$t('main.pwsetsucc'), {buttons:[{label:this.$t('main.confIrm'),onClick:() => { this.canvas.className = '' }}]})

				}
			})
		},
		showPopPusBtn() {
			this.showPopPus = true
			this.canvas.className = 'maxCanvas'
		},
		concatShow(index) {
			if (index == 1) {
				this.showPopPus = false
				this.canvas.className = ''
				return
			} else if (index == 2) {
				if (!this.ds_data.pic) {
					this.alert = weui.alert(this.$t('main.pulsi'), { buttons: [{ label: this.$t('main.confIrm') }] })
					this.alert.className = 'tran90'
					return
				}
				if (this.cahngePop == 0) {
					this.axios.post("/artist/stamp", {
						stamp: this.ds_data.pic,
						type: 1
					})
						.then((res) => {
							if (res.data.code == 0) {
								this.axios.get('/artist/stamp?type=1')
									.then((res) => {
										this.dsDataIsShow.pic = res.data.data.stamp
										this.dsDataShow.pic = res.data.data.stamp
										this.cahngePop = 1
										this.stamp_id = res.data.data.id
										this.alert = (this.$t('main.submitsucc'), () => { this.canvas.className = '' })
										this.alert.className = 'tran90'
										this.showPopPus = false

									})
							} else {
								this.alert = weui.alert(res.data.msg, { buttons: [{ label: this.$t('main.confIrm') }] })
								this.alert.className = 'tran90'
								return

							}

						})
				} else {
					this.axios.put('/artist/stamp/' + this.stamp_id, {
						stamp: this.ds_data.pic,
						type: 1
					}).then((res) => {
						if (res.data.code == 0) {
							this.axios.get('/artist/stamp?type=1')
								.then((res) => {
									this.dsDataIsShow.pic = res.data.data.stamp
									this.dsDataShow.pic = res.data.data.stamp
									this.alert = weui.alert(this.$t('main.modifySucc'), {buttons:[{label:this.$t('main.confIrm'),onClick:() => { this.canvas.className = '' }}]})
									this.alert.className = 'tran90'
									this.showPopPus = false

									// this.ctx.clearRect(450,50,120,120)
									// let yinzhang = document.getElementById('yinzhang')
									// this.ctx.drawImage(yinzhang,450,50,120,120)
								})
						} else {
							this.alert = weui.alert(res.data.msg, { buttons: [{ label: this.$t('main.confIrm') }] })
							this.alert.className = 'tran90'
							return
						}

					})
				}

			}
		},
		changePassSubmit() {
			this.axios.post('/artist/sign_password/edit', {
				old_password: this.old_password,
				password: this.password,
				password_confirm: this.confirm_password
			}).then((res) => {

				if (res.data.code == 0) {

					this.password_pop_flag = false
					this.old_password = ''
					this.password = ''
					this.confirm_password = ''
					weui.alert(this.$t('main.pwcsucc'), {buttons:[{label:this.$t('main.confIrm'),onClick:() => { this.canvas.className = '' }}]})


				} else {
					this.err_tips = true
					this.err_tips_text = res.data.msg
				}
			})
		},
		checkedFlag() {
			this.isKnow = !this.isKnow
		},
		passSubmit() {
			if (this.changePass) { //修改密码
				if (this.old_password != '' && this.password != '' && this.confirm_password != '') {

					if (this.old_password.length < 6 || this.password.length < 6 || this.confirm_password.length < 6) {
						this.err_tips = true
						this.err_tips_text = this.$t('main.pwdtips')
						return
					}
					if (this.password != this.confirm_password) {
						this.err_tips = true
						this.err_tips_text = this.$t('main.noMatch')
					} else {
						this.err_tips = false
						this.changePassSubmit()
					}
				} else {
					this.err_tips = true
					this.err_tips_text = this.$t('main.penterpwd')
				}
			} else { //设置密码
				if (this.password != '' && this.confirm_password != '') {
					if (this.password.length < 6 || this.confirm_password.length < 6) {
						this.err_tips = true
						this.err_tips_text = this.$t('main.pwdtips')
						return
					}
					if (this.password != this.confirm_password) {
						this.err_tips = true
						this.err_tips_text = this.$t('main.noMatch')
					} else {
						this.err_tips = false
						this.addPasswordSubmit()
					}
				} else {
					this.err_tips = true
					this.err_tips_text = this.$t('main.penterpwd')
				}
			}

		},
		submitAutoGraph() {

			if (this.isKnow) {
				if (this.qm_flag) {
					this.saveCanvas()
				} else {

					let msg = this.$t('main.psf')
					if (this.no_pass) {
						msg = this.$t('main.peditsigntoup')
					}
					this.canvas.className = 'maxCanvas'
					this.alert = weui.alert(msg, {buttons:[{label:this.$t('main.confIrm'),onClick:() => {
						this.canvas.className = ''
					}}]})
					this.alert.className = 'tran90'
				}

			} else {
				this.canvas.className = 'maxCanvas'
				this.alert = weui.alert(this.$t('main.paoaf'), {buttons:[{label:this.$t('main.confIrm'),onClick:() => {
					this.canvas.className = ''
				}}]})
				this.alert.className = 'tran90'
			}
		},
		showPop() {
			this.password_pop_flag = true
			this.canvas.className = 'maxCanvas'
		},
		colsePop() {
			this.password_pop_flag = false
			this.old_password = ''
			this.password = ''
			this.confirm_password = ''
			this.err_tips = false
			this.canvas.className = ''
		},
		postAutoGraph(val) {
			this.loading = weui.loading(this.$t('main.submiting'))
			this.axios.post('/artist/sign', {
				sign: val
			}).then((res) => {

				if (res.data.code == 0) {
					this.getAutoGraph()
					this.is_shz = true
					this.no_pass = false
					this.loading.hide()
					if (!this.changePass) {
						this.alert = weui.alert(this.$t('main.suspsspwd'), {buttons:[{label:this.$t('main.confIrm'),onClick:() => { this.showPop() }}]})
						this.alert.className = 'tran90'
					} else {
						this.alert = weui.alert(this.$t('main.signupsucc'), { buttons: [{ label: this.$t('main.confIrm') }] })
						this.alert.className = 'tran90'
					}


				} else {
					this.loading.hide()
					this.alert = weui.alert(res.data.msg, { buttons: [{ label: this.$t('main.confIrm') }] })
					this.alert.className = 'tran90'
				}
			}).catch((err) => {
				this.alert = weui.alert(this.$t('main.submitfail'), { buttons: [{ label: this.$t('main.confIrm') }] })
				this.alert.className = 'tran90'
				this.loading.hide()
			})
		},
		editAutoGraph(val) {
			this.axios.put('/artist/sign/' + this.autoGraphInfo.id, {
				sign: val
			}).then((res) => {

				if (res.data.code == 0) {

					if (!this.changePass) {
						this.alert = weui.alert(this.$t('main.signeditsetpwd'), {buttons:[{label:this.$t('main.confIrm'),onClick:() => { this.showPop() }}]})
						this.alert.className = 'tran90'
					} else {
						this.alert = weui.alert(this.$t('main.signeditsu'), { buttons: [{ label: this.$t('main.confIrm') }] })
						this.alert.className = 'tran90'
					}
					this.is_shz = true
					this.no_pass = false
				} else {

					this.alert = weui.alert(res.data.msg, { buttons: [{ label: this.$t('main.confIrm') }] })
					this.alert.className = 'tran90'
				}
			})
		},
		clearBtn() {

			this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
			// if(this.dsDataShow.pic){
			// 	// this.originSignData = this.ctx.getImageData(0,0,this.canvas.width,this.canvas.height)
			// 	let yinzhang = document.getElementById('yinzhang')

			// 	this.ctx.drawImage(yinzhang,450,50,120,120)
			// }
			this.qm_flag = false
		},
		createCanvas(index) {
			this.ele = this.$refs.canvas;
			this.canvas = document.createElement("canvas");

			this.ele.appendChild(this.canvas);

			if (index) {
				this.canvas.width = this.ele.clientWidth;
				this.canvas.height = this.$refs.canvas_out.clientHeight;
			} else {
				if (this.password_pop_flag) {
					this.canvas.className = 'maxCanvas'
				}
				this.canvas.width = this.ele.clientWidth;
				this.canvas.height = this.ele.clientHeight;
			}

			this.ctx = this.canvas.getContext("2d");
			this.ctx.strokeStyle = "#000000";
			this.ctx.lineWidth = 4;
			this.ctx.lineCap = "round";
			// if(this.dsDataShow.pic){
			// 	// this.originSignData = this.ctx.getImageData(0,0,this.canvas.width,this.canvas.height)
			// 	let yinzhang = document.getElementById('yinzhang')

			// 	this.ctx.drawImage(yinzhang,450,50,120,120)
			// }


			// if(window.orientation == 0){
			// 	this.ctx.translate(this.ele.clientWidth, 0)
			// 	this.ctx.rotate(Math.PI / 2)
			// }
			let _this = this

			this.canvas.addEventListener(
				"touchstart",
				function (e) {

					this.qm_flag = true
					window.orientation == 0

					this.ctx.beginPath();
					if (window.orientation == 0) {
						this.ctx.moveTo(

							e.changedTouches[0].pageY,
							this.height - e.changedTouches[0].pageX
						);
					} else {
						this.ctx.moveTo(

							e.changedTouches[0].pageX + 2,
							e.changedTouches[0].pageY
						);
					}

				}.bind(this),
				false
			);
			let newImg = new Image()
			if (this.autoGraphInfo.sign) {
				this.isKnow = true
				newImg.setAttribute('crossOrigin', 'anonymous')
				newImg.onload = () => {

					if (this.canvas.width / this.canvas.height <= 3.13) {
						let nowW = this.canvas.width
						let nowH = newImg.height * (this.canvas.width / newImg.width)
						let thiH = (this.canvas.height - nowH) / 2
						this.ctx.drawImage(newImg, 0, thiH, nowW, nowH);
					} else {
						let nowH = this.canvas.height
						let nowW = newImg.width * (this.canvas.height / newImg.height)
						let thiW = (this.canvas.width - nowW) / 2
						this.ctx.drawImage(newImg, thiW, 0, nowW, nowH);
					}

				}
				let timeStamp = new Date().getTime()
				newImg.src = this.autoGraphInfo.sign + '?' + timeStamp;
			}



			this.canvas.addEventListener(
				"touchmove",
				function (e) {
					if (window.orientation == 0) {

						this.ctx.lineTo(
							e.changedTouches[0].pageY,
							this.height - e.changedTouches[0].pageX
						);
					} else {
						this.ctx.lineTo(
							e.changedTouches[0].pageX + 2,
							e.changedTouches[0].pageY
						);
					}

					this.ctx.stroke();
					e.preventDefault()
				}.bind(this),
				false
			);
			this.canvas.addEventListener(
				"touchend",
				function (e) {
					if (this.canvas_list.length > 20) {
						this.canvas_list.shift()
					}
					this.canvas_list.push(this.ctx.getImageData(
						0,
						0,
						this.canvas.width,
						this.canvas.height
					))
				}.bind(this),
				false
			);
		},
		saveCanvas() {
			const imgView = new Image();
			const canvas = document.createElement("canvas");
			const context = canvas.getContext("2d");
			canvas.width = 780
			canvas.height = 270
			imgView.onload = () => {
				const imgW = imgView.width;
				const imgH = imgView.height;
				if (imgW / imgH >= 3.13) {
					let nowW = 780
					let nowH = imgH / (imgW / 780)
					let cenH = (270 - nowH) / 2
					context.drawImage(imgView, 0, cenH, nowW, nowH);
				} else {
					let nowH = 270
					let nowW = imgW / (imgH / 270)
					let cenW = (780 - nowW) / 2

					context.drawImage(imgView, cenW, 0, nowW, nowH);
				}

				let param = new FormData();
				let image1 = this.dataURLtoBlob(canvas.toDataURL("image/png"))
				param.append("file", image1, 'test.png', {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				});

				this.axios.post("/common/uploadImg", param).then((res) => {
					if (res.data.code == 0) {

						if (this.autoGraphInfo.id) {
							this.editAutoGraph(res.data.data.url)
						} else {
							this.postAutoGraph(res.data.data.url)
						}

					} else {
						this.alert = weui.alert(this.$t('main.tpitltu'), { buttons: [{ label: this.$t('main.confIrm') }] })
						this.alert.className = 'tran90'
					}
				});
			}
			imgView.src = this.clip()
		},
		showContent() {
			this.alert = Dialog.alert({ message: this.xy.content, confirmButtonColor: '#2d71d7', confirmButtonText: this.$t('main.confIrm') })
			this.alert.className = 'tran90'
		},
		// tran90(url) {
		//   const imgView = new Image();
		//   const canvas = document.createElement("canvas");
		//   const context = canvas.getContext("2d");
		//   const cutCoor = { sx: 0, sy: 0, ex: 0, ey: 0 };
		//   imgView.onload = () => {
		//     const imgW = imgView.width;
		//     const imgH = imgView.height;
		//     const size = imgH;
		//     canvas.width = size * 2;
		//     canvas.height = size * 2;
		//     cutCoor.sx = size;
		//     cutCoor.sy = size - imgW;
		//     cutCoor.ex = size + imgH;
		//     cutCoor.ey = size + imgW;
		//     context.translate(size, size);
		//     context.rotate((Math.PI / 2) * 3);
		//     context.drawImage(imgView, 0, 0);
		//     const imgData = context.getImageData(
		//       cutCoor.sx,
		//       cutCoor.sy,
		//       cutCoor.ex,
		//       cutCoor.ey
		//     );
		//     canvas.width = imgH;
		//     canvas.height = imgW;
		//     context.putImageData(imgData, 0, 0);
		//     
		//   };
		//   imgView.src = url;
		// },
		clip() {

			const imgData = this.ctx.getImageData(
				0,
				0,
				this.canvas.width,
				this.canvas.height
			);
			const clipArea = this.getCropArea(imgData.data);


			const _canvas = document.createElement("canvas");
			_canvas.width = clipArea.x2 - clipArea.x1;
			_canvas.height = clipArea.y2 - clipArea.y1;
			const _ctx = _canvas.getContext("2d");

			const imageData = this.ctx.getImageData(
				clipArea.x1,
				clipArea.y1,
				_canvas.width,
				_canvas.height
			);
			_ctx.putImageData(imageData, 0, 0);
			const base64 = _canvas.toDataURL("image/png");


			return base64
		},
		clip1(imgData, ctx) {

			const clipArea = this.getCropArea1(imgData.data, ctx.canvas);

			const _canvas = document.createElement("canvas");
			_canvas.width = clipArea.x2 - clipArea.x1;
			_canvas.height = clipArea.y2 - clipArea.y1;
			const _ctx = _canvas.getContext("2d");

			const imageData = ctx.getImageData(
				clipArea.x1,
				clipArea.y1,
				_canvas.width,
				_canvas.height
			);
			_ctx.putImageData(imageData, 0, 0);
			const base64 = _canvas.toDataURL("image/png");
			return base64
		},
		restoreBtn() {
			if (this.canvas_list.length > 1) {
				this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
				this.canvas_list.pop()

				this.ctx.putImageData(
					this.canvas_list[this.canvas_list.length - 1],
					0,
					0
				)
			} else if (this.canvas_list.length == 1) {
				this.canvas_list.pop()
				this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
				this.qm_flag = false
			} else {
				return
			}

		},
		getCropArea1(imgData, canvas) {
			let x1 = Math.round(canvas.width);
			let y1 = Math.round(canvas.height);
			let x2 = 0;
			let y2 = 0;


			for (let i = 0; i < Math.round(canvas.width); i++) {
				for (let j = 0; j < Math.round(canvas.height); j++) {
					let pos = (i + Math.round(canvas.width) * j) * 4;
					if (
						imgData[pos] > 0 ||
						imgData[pos + 1] > 0 ||
						imgData[pos + 2] ||
						imgData[pos + 3] > 0
					) {
						// 判断第j行第i列的像素不是透明的
						// 找到有色彩的左上角坐标和右下角坐标
						x1 = Math.min(i, x1);
						y1 = Math.min(j, y1);
						x2 = Math.max(i, x2);
						y2 = Math.max(j, y2);
					}
				}
			}
			x1++;
			y1++;
			x2++;
			y2++;
			return {
				x1,
				y1,
				x2,
				y2
			}; // 由于循环是从0开始的,而我们认为的行列是从1开始的
		},
		// 计算空白区域
		getCropArea(imgData) {
			let x1 = Math.round(this.canvas.width);
			let y1 = Math.round(this.canvas.height);
			let x2 = 0;
			let y2 = 0;


			for (let i = 0; i < Math.round(this.canvas.width); i++) {
				for (let j = 0; j < Math.round(this.canvas.height); j++) {
					let pos = (i + Math.round(this.canvas.width) * j) * 4;
					if (
						imgData[pos] > 0 ||
						imgData[pos + 1] > 0 ||
						imgData[pos + 2] ||
						imgData[pos + 3] > 0
					) {
						// 判断第j行第i列的像素不是透明的
						// 找到有色彩的左上角坐标和右下角坐标
						x1 = Math.min(i, x1);
						y1 = Math.min(j, y1);
						x2 = Math.max(i, x2);
						y2 = Math.max(j, y2);
					}
				}
			}
			x1++;
			y1++;
			x2++;
			y2++;
			return {
				x1,
				y1,
				x2,
				y2
			}; // 由于循环是从0开始的,而我们认为的行列是从1开始的
		},
		getBase64Image(img) {
			let canvas1 = document.createElement("canvas");
			let ctx1 = canvas1.getContext('2d')
			ctx1.drawImage(img, 0, 0, img.width, img.height)
			let ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase()
			let dataURL = canvas.toDataURL('image/' + ext)
			return dataURL
		},

		dataURLtoBlob(dataurl) {
			var arr = dataurl.split(',')
			// 
			var mime = 'image/png'

			var bstr = arr[1] ? atob(arr[1]) : atob(arr[0])
			var n = bstr.length
			var u8arr = new Uint8Array(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new Blob([u8arr], {
				type: mime
			})
		},
		showHelpContent(key) {
			Dialog.alert({ message: this.help[key], confirmButtonColor: '#2d71d7', confirmButtonText: this.$t('main.confIrm'), confirmButtonText: this.$t('main.confIrm') })
		},
		upLoadImg() {
			let _this = this

			wx.chooseImage({
				count: 1, // 默认9
				sizeType: ["compressed"], // 可以指定是原图还是压缩图，默认二者都有
				sourceType: ["album"], // 可以指定来源是相册还是相机，默认二者都有
				success: (res) => {

					var localIds = res.localIds; // 返回选定照片的本地 ID 列表，localId可以作为 img 标签的 src 属性显示图片
					this.showPic.status = 'loading'
					this.showPic.url = 'http://t.6a.com/87/01193881f683a494.png'
					wx.getLocalImgData({
						localId: localIds[0], // 图片的localID
						success: (res) => {
							var localData = res.localData; // localData是图片的base64数据，可以用 img 标签显示


							var canvas = document.createElement('canvas')
							this.outline = 70
							var ctx = canvas.getContext('2d')
							var img = new Image();
							if (localData.indexOf(',') == -1) {
								localData = 'data:image/jpg;base64,' + localData
							}
							this.showPic.url = localData
							img.onload = () => {

								let image1
								if (img.width > 1024 || img.height > 1024) {
									if (img.width > img.height) {
										let wh = img.width / img.height
										canvas.width = 1024
										canvas.height = 1024 / wh
										ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

										let originalData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
										this.dealImg(ctx, originalData, ctx.canvas.width, ctx.canvas.height)

										image1 = this.clip1(ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height), ctx)

									} else {
										let hw = img.height / img.width
										canvas.width = 1024 / hw
										canvas.height = 1024
										ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

										let originalData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
										this.dealImg(ctx, originalData, ctx.canvas.width, ctx.canvas.height)

										image1 = this.clip1(ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height), ctx)

									}
								} else {
									canvas.width = img.width
									canvas.height = img.height
									ctx.drawImage(img, 0, 0, img.width, img.height)
									let originalData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
									this.dealImg(ctx, originalData, ctx.canvas.width, ctx.canvas.height)
									image1 = this.clip1(ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height), ctx)


								}


								// param.append("file", image1, 'test.png', {
								// 	headers: {
								// 		"Content-Type": "multipart/form-data",
								// 	},
								// });
								let image2;
								let param2 = new FormData();
								const imgView2 = new Image();
								const canvas2 = document.createElement("canvas");
								const context2 = canvas2.getContext("2d");
								canvas2.width = 270
								canvas2.height = 270
								imgView2.onload = () => {

									const imgW = imgView2.width;
									const imgH = imgView2.height;
									if (imgW / imgH >= 1) {
										let nowW = 270
										let nowH = imgH / (imgW / 270)
										let cenH = (270 - nowH) / 2
										context2.drawImage(imgView2, 0, cenH, nowW, nowH);
									} else {

										let nowH = 270
										let nowW = imgW / (imgH / 270)
										let cenW = (270 - nowW) / 2
										context2.drawImage(imgView2, cenW, 0, nowW, nowH);
									}

									image2 = this.dataURLtoBlob(canvas2.toDataURL("image/png"))
									param2.append("file", image2, 'test.png', {
										headers: {
											"Content-Type": "multipart/form-data",
										},
									});
									this.axios.post("/common/uploadImg", param2).then((res) => {
										if (res.data.code == 0) {
											_this.ds_data.pic = res.data.data.url
											_this.dsDataIsShow.pic = res.data.data.url
											this.showPic.status = 'done'

										} else {

											this.alert = weui.alert('图片太大上传失败', { buttons: [{ label: this.$t('main.confIrm') }] })
											this.alert.className = 'tran90'
										}
									});

								}

								imgView2.src = image1;

							}
							img.onerror = () => {
								let image1 = this.dataURLtoBlob(localData);
								let param = new FormData();
								param.append("file", image1, 'test.png', {
									headers: {
										"Content-Type": "multipart/form-data",
									},
								});

								this.axios.post("/common/uploadImg", param).then((res) => {
									_this.ds_data.pic = res.data.data.url
									_this.dsDataIsShow.pic = res.data.data.url
								});
							}
							img.src = localData

						},
					});
				},
			});
		},
		dataURLtoBlob(dataurl) {
			var arr = dataurl.split(',')
			// console.log(arr)
			var mime = 'image/png'

			var bstr = arr[1] ? atob(arr[1]) : atob(arr[0])
			var n = bstr.length
			var u8arr = new Uint8Array(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new Blob([u8arr], {
				type: mime
			})
		},
		dealImg(ctx, originImg, width, height) {
			let tl = Array.prototype.slice.call(ctx.getImageData(0, 0, 1, 1).data);
			let tr = Array.prototype.slice.call(ctx.getImageData(width - 1, 0, 1, 1).data);
			let br = Array.prototype.slice.call(ctx.getImageData(width - 1, height - 1, 1, 1).data);
			let bl = Array.prototype.slice.call(ctx.getImageData(0, height - 1, 1, 1).data);

			let avgPixel = [];
			for (let i = 0; i < 4; i++) {
				avgPixel[i] = (tl[i] + tr[i] + br[i] + bl[i]) / 4;
			}

			let imgPixel = originImg;
			let imgPixelData = imgPixel.data;
			for (let i = 0; i < imgPixelData.length; i += 4) {
				let pixelR = imgPixelData[i];
				let pixelG = imgPixelData[i + 1];
				let pixelB = imgPixelData[i + 2];

				let outFlag = [pixelR, pixelG, pixelB].every((item, index) => {
					return item > (avgPixel[index] - this.outline) && item < (avgPixel[index] + this.outline)
				})
				if (outFlag) {
					imgPixelData[i + 3] = 0;
				}
			}

			ctx.putImageData(imgPixel, 0, 0);
			// dataUrl = canvas.toDataURL("image/png");
			// 由于浏览器实现，a标签href无法承载过长base64，所以对于大图片需要转bloburl下载，否则会出现network error
			// canvas.toBlob(function(blob) {
			//     blobUrl = URL.createObjectURL(blob);
			//     showAfter.src = blobUrl;
			// });
		}
	}
};
</script>

<style scoped>
.maxWH {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 2;
}

:deep(.maxCanvas) {
	display: none;
}

.button_fixed1 {
	padding: 0.04rem 0.15rem;
	font-size: 0.14rem;
	position: absolute;
	z-index: 3;
	top: 0.15rem;
	left: 1rem;
	background: #3d6e7f;
	box-sizing: border-box;
	color: #FFFFFF;
	border-radius: 0.05rem;
	display: flex;
	align-items: center;
}

.button_fixed1 img {
	height: 0.14rem;
	object-fit: contain;
	margin-right: 0.08rem;
}

.button_fixed {
	padding: 0.04rem 0.15rem;
	font-size: 0.14rem;
	position: absolute;
	z-index: 3;
	top: 0.15rem;
	left: 0.15rem;
	background: #3d6e7f;
	box-sizing: border-box;
	color: #FFFFFF;
	border-radius: 0.05rem;
	display: flex;
	align-items: center;
}

.button_fixed img {
	width: 0.11rem;
	margin-right: 0.08rem;
}

.out {


	min-height: 100vh;

	box-sizing: border-box;
	background: url('../assets/img/auto-bg.png') center;
	background-size: 100%;
	padding-bottom: 0.15rem;
	padding: 0 0.07rem;
	padding-top: 0.1rem;
	overflow: hidden;
}

.autograph-box {
	height: 2.8rem;
	background: url(../assets/img/sing-canvas-bg.png) #fff no-repeat center;
	background-size: 100% 100%;
	position: relative;
}

.canvas-bg-j {
	background: #fff;
	position: absolute;
	width: 0.15rem;
}

.canvas-bg-j.left-top {
	left: -1px;
	top: -1px;
}

.canvas-bg-j.right-top {
	right: -1px;
	top: -1px;
}

.canvas-bg-j.left-bottom {
	left: -1px;
	bottom: -1px;
}

.canvas-bg-j.right-bottom {
	right: -1px;
	bottom: -1px;
	/* padding: 0.1rem 0 0 0.1rem; */
}


.content {
	margin-top: 0.1rem;
}

.qm-tj-box {
	display: flex;
	justify-content: space-between;
	font-size: 0.14rem;
	margin-bottom: 0.13rem;
	align-items: center;
}

.set-pass-box {
	display: flex;
	align-items: center;
	padding: 0.1rem 0.15rem;
	color: #ffffff;
	border-radius: 0.05rem;
	background: #943346;
	line-height: 1;

}

.set-pass-box img {
	margin-right: 0.07rem;
	width: 0.115rem;
}

.submit-btn {
	display: flex;
	align-items: center;
	padding: 0.1rem 0.15rem;
	color: #ffffff;
	border-radius: 0.05rem;
	background: #a12f2a;
	line-height: 1;

}

.submit-btn1 {
	display: flex;
	align-items: center;
	padding: 0.08rem 0.12rem;
	color: #ffffff;
	border-radius: 0.05rem;
	background: #a12f2a;
	line-height: 1;
	position: absolute;
	bottom: 7%;
	right: 5%;
	z-index: 3;
}

.submit-btn1 img {
	margin-right: 0.07rem;
	width: 0.115rem;
}

.submit-btn img {
	margin-right: 0.07rem;
	width: 0.115rem;
}

.agree-tip-box {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 0.12rem;
	color: #343434;
	flex-wrap: wrap;
}

.agree-box-out {
	display: flex;
	align-items: center;
	line-height: normal;
	margin-bottom: 0.05rem;
}

.agree-box-tips {
	margin-bottom: 0.05rem;
}

.agree-box input {
	display: none;
}

.agree-box {
	display: flex;
	align-items: center;
	position: relative;
}

.agree-box label::before {
	display: inline-block;
	content: "";
	width: 0.175rem;
	height: 0.175rem;
	background-color: #ffffff;
	border-radius: 50%;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	position: absolute;
	border: 1px solid #bfbfbf;
	box-sizing: border-box;
}

.agree-box input:checked+label::before {
	display: inline-block;
	content: "";
	width: 0.175rem;
	height: 0.175rem;
	border-radius: 50%;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	background: url(../assets/img/agree-icon.png) no-repeat center;
	background-size: cover;


}

.agree-box label {
	font-weight: 500;
	letter-spacing: 0px;
	margin-left: 0.275rem;
	flex-grow: 1;
	border-radius: 0.05rem;
}

.password-box .agree-box-out {
	padding-left: 12%;
	margin-top: 0.075rem;
	margin-bottom: 0.075rem;
}

.password-box .pop-in .con .pass-input>div label {
	min-width: 12%;
}

.password-box .pop-in .con .err-tips {
	padding-left: 12%;
}

.password-box .pop-in .confirm-btn {
	margin: 0 auto;
}

.pop {
	position: fixed;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	top: 0;
	left: 0;
	z-index: 4;
}

.pop-in {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 0.15rem;
	color: #171717;
	font-size: 0.12rem;
	width: 80%;
	background: url(../assets/img/pop-bg.png) no-repeat center;
}

.close-pop-btn {
	position: absolute;
	top: 0.15rem;
	right: 0.15rem;
	width: 0.23rem;
}

.pop-in .title {
	font-size: 0.18rem;
	display: flex;
	align-items: center;
}

.pop-in .title span {
	margin: 0 0.1rem;
}

.pop-in .title img {
	width: 0.18rem;
}

.pop-in .con .con-tips {
	margin: 0.2rem 0;
}

.pop-in .con .pass-input>div {
	display: flex;
	align-items: center;
	margin-bottom: 0.075rem;
}

.pop-in .con .pass-input>div label {
	width: 0.6rem;
	text-align: right;
	box-sizing: border-box;

}

.pop-in .con .pass-input>div input {
	height: 0.33rem;
	line-height: 0.33rem;
	font-size: 0.14rem;
	border-radius: 0.05rem;
	border: none;
	outline: none;
	padding-left: 0.1rem;
	width: 2.1rem;
	margin-left: 0.1rem;
	box-sizing: border-box;
}

.pop-in .con .err-tips {
	padding-left: 20%;
	display: flex;
	align-items: center;
	color: #ff180e;
}

.pop-in .con .err-tips img {
	width: 0.13rem;
	margin-right: 0.1rem;
}

.pop-in .confirm-btn {
	font-size: 0.14rem;
	height: 0.33rem;
	border-radius: 0.05rem;
	width: 100%;
	margin-left: 20%;
	width: 70%;
	text-align: center;
	color: #ffffff;
	background: #9a2a25;
	line-height: 0.33rem;
	margin-top: 0.15rem;
}

.pop-in .con .no-set-pass .err-tips {
	padding-left: 0;
	margin-bottom: 0.2rem;
	line-height: 1;
}

.agree-text {
	color: #a12f2a;
}

input::-webkit-input-placeholder {
	font-size: 0.12rem;
}

.qm-text {
	position: absolute;
	top: 50%;
	width: 50%;
	left: 40%;
	transform: translate(-50%, -50%);
	z-index: 1;
	opacity: 0.3;
}

.qm-yinzhang-logo {
	position: absolute;
	top: 50%;
	width: 22%;
	height: 1.5rem;
	right: -8%;
	transform: translate(-50%, -50%);
	z-index: 1;
	object-fit: contain;
}

.text-status {
	position: absolute;
	bottom: 8%;
	right: 25%;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	color: red;
}

.err {
	flex: 1;
	text-align: right;
	box-sizing: border-box;
	padding: 0 0.06rem;
	color: #ff180e;
}

.question {
	width: 0.2rem;
	height: 0.2rem;
	margin-left: 0.06rem;
	margin-right: 0.06rem;
}

@media (orientation: portrait) {
	.out {
		/* position:relative; */
		min-width: 100vh;
		min-height: 100vw;
		transform: rotate(90deg) translate(0, -100vmin);
		;
		transform-origin: 0 0;


		-webkit-transform: rotate(90deg) translate(0, -100vmin);
		;
		-moz-transform: rotate(90deg) translate(0, -100vmin);
		;
		-ms-transform: rotate(90deg) translate(0, -100vmin);
		;
		transform: rotate(90deg) translate(0, -100vmin);
		;
		transform-origin: 0% 0%;
	}

	.pop-in {
		position: fixed;
		/* top: 50vw;
			left:50vh; */
		z-index: 10;
	}

	/* 竖屏CSS */
	.marsk-pop {
		display: none;
		position: fixed;
		background: #FFFFFF;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 5;
	}

	.marsk-pop-in {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #FFFFFF;
		font-size: 0.2rem;
		text-align: center;
		width: 70%;
		color: #171717;
	}

	.marsk-pop-in div {
		margin-top: 0.1rem;
	}

	.marsk-pop-in p {
		font-size: 0.14rem;
		color: #a12f2a;
		margin-top: 0.1rem;
	}
}

@media (orientation: landscape) {
	@media screen and (min-width:500px) {

		/* 横屏CSS */
		.marsk-pop {
			display: none;
		}

		.autograph-box {
			height: 55vh;
		}

		.question {
			width: 0.1rem;
			height: 0.1rem;
		}

		.button_fixed {
			font-size: 0.08rem;
			padding: 0.02rem 0.075rem;
			top: 0.07rem;
			right: 0.07rem;

			border-radius: 0.025rem;
		}

		.button_fixed1 {
			top: 0.07rem;
			left: 0.07rem;
			font-size: 0.08rem;
			padding: 0.02rem 0.075rem;
			border-radius: 0.025rem;
		}

		.button_fixed1 img {
			height: 0.08rem;
			margin-right: 0.04rem;
		}

		.button_fixed img {
			width: 0.0551rem;
			margin-right: 0.04rem;
		}

		.qm-tj-box {
			font-size: 0.08rem;
		}

		.set-pass-box {
			padding: 0.05rem 0.1rem;
			border-radius: 0.025rem;
			line-height: inherit;
		}

		.submit-btn {
			padding: 0.05rem 0.11rem;
			border-radius: 0.025rem;
		}

		.agree-tip-box {
			font-size: 0.08rem;
		}

		.agree-box label {
			margin-left: 0.175rem;
		}

		.agree-box input:checked+label::before {
			width: 0.105rem;
			height: 0.105rem;
		}

		.agree-box label::before {
			width: 0.105rem;
			height: 0.105rem;
		}

		.set-pass-box img {
			width: 0.065rem;
			margin-right: 0.035rem;
		}

		.submit-btn img {
			width: 0.08rem;
			margin-right: 0.035rem;
		}

		.pop-in {
			font-size: 0.08rem;
			padding: 0.07rem;
			width: 60%;
		}


		.pop-in .con .pass-input>div {
			margin-bottom: 0.04rem;
		}

		.pop-in .con .pass-input>div input {
			height: 0.175rem;
			line-height: 0.175rem;
			font-size: 0.08rem;
			border-radius: 0.025rem;
		}

		.pop-in .con .con-tips {
			margin: 0.05rem 0;
		}

		.pop-in .title {
			font-size: 0.1rem;
		}

		.pop-in .title img {
			width: 0.09rem;
		}

		.pop-in .confirm-btn {
			font-size: 0.08rem;
			height: 0.175rem;
			line-height: 0.175rem;
			margin-top: 0.07rem;
			border-radius: 0.025rem;
		}

		.close-pop-btn {
			width: 0.11rem;
			top: 0.07rem;
			right: 0.07rem;
		}

		.pop-in .con .no-set-pass .err-tips {
			margin-bottom: 0.1rem;
		}

		.pop-in .con .err-tips img {
			width: 0.08rem;
			margin-right: 0.05rem;
		}

		.canvas-bg-j.left-top {
			padding: 0 0.05rem 0.05rem 0;
		}

		.canvas-bg-j.right-top {
			padding: 0 0 0.05rem 0.05rem;
		}

		.canvas-bg-j.left-bottom {
			padding: 0.05rem 0.05rem 0 0;
		}

		.canvas-bg-j.right-bottom {
			padding: 0.05rem 0 0 0.05rem;
		}

		.canvas-bg-j {
			width: 0.07rem;
		}

		input::-webkit-input-placeholder {
			font-size: 0.08rem;
		}

		/* .pop-in{
				width: 90%;
				font-size: 14px !important;
			}
			.pop-in .title img{
				width:18px;
			}
			.pop-in .con .err-tips img{
				width: 13px;
			}
			.pop-in .confirm-btn{
				margin-top:20px;
				font-size: 14px !important;
				height: 33px;
				line-height: 33px;
			}
			input::-webkit-input-placeholder {
				font-size: 12px;
			}
			.pop-in .con .pass-input>div{
				margin-bottom:7px;
			}
			.pop-in .con .pass-input>div input{
				height: 30px;
				font-size: 12px;
				
			}
			.pop-in .con .pass-input>div label{
				min-width: 23%;
			}
			.pop-in .title{
				font-size: 18px;
			}
			.close-pop-btn{
				width: 18px;
				height: 18px;
			} */

	}

}

.pop-pus {
	z-index: 10;
	background-color: rgba(0, 0, 0, .5);
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
}

.pop-pus .bg-img {
	width: 70%;
	height: 3rem;
	background: url(../assets/img/sign-toast-prompt.png) no-repeat center;
	background-size: 100% 100%;
	position: relative;
	z-index: 10;
}



.pop-pus .sure-logo {
	position: absolute;
	bottom: 0;
	margin-bottom: 0.25rem;
	width: 100%;
	padding: 0 0.4rem;

	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
}

.pop-pus .sure-logo .sure-img {

	background-size: 100% 100%;
	width: 45%;
	height: 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.sure-logo .cencan {
	background: url(../assets/img/sign-cencan.png) no-repeat center;
	color: #F8D9AE;
}

.sure-logo .tijiao {
	background: url(../assets/img/sign-tijiao.png) no-repeat center;
}

.imgupdate {
	display: flex;
	justify-content: space-between;
	padding: 0.3rem 0.3rem 0.3rem 0.4rem;
}

.imgupdate .sc-tucheng {
	width: 1.5rem;
	height: 1.5rem;
	position: relative;
	overflow: hidden;
	border-radius: 0.05rem;
	background: url(../assets/img/sign-tucheng.png) no-repeat center;
	background-size: 100% 100%;
}

.imgupdate .sc-tucheng .dibu {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.imgupdate .tanchuang {
	width: 100%;
	height: 0.3rem;
	background: rgba(0, 0, 0, 0.4);
	position: absolute;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-size: 0.14rem;
}

.sc-btn {}

.sc-btn .title {
	color: white;
	font-size: 0.12rem;
	margin-top: 0.15rem;
}

.sc-btn .title span {
	color: #F8F008;
}

.sc-btn-item {
	width: 1.2rem;
	height: 1.2rem;
	line-height: 1rem;
	text-align: center;
	border-radius: 0.06rem;
	background: #F2E4D9;
	/* box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3); */
	font-size: 0.25rem;
	display: flex;
	justify-content: center;
	align-items: center;
	color: rgba(0, 0, 0, 0.3);
	overflow: hidden;
}

.sc-btn-item img {
	width: 100%;
	height: 100%;
	display: block;
	object-fit: contain;
}

.loading_i {
	position: absolute;
	width: 1.2rem;
	height: 1.2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: #1989fa;
	font-size: 0.12rem;
	background: rgba(0, 0, 0, 0.6);
}

:deep(.van-loading__text) {
	font-size: 0.12rem;
	line-height: 0.12rem;
	margin: 0;
	margin-top: 0.1rem;
}

#yinzhang {
	display: none;
}</style>
